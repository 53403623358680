import React, { useEffect, useState } from "react";
import { Navbar } from '../../components/Navbar';
import Photocapa from "../../assets/photo-capa.png";
import { User } from "../../components/User";
import './Autenticacion.styles.css';
import QrReader from 'react-qr-reader';

import { api } from '../../services/api';

import { ButtonLogout } from "../../components/Forms/ButtonLogout";


export const Autenticacion = () => {

    const [status,setStatus] = useState(0);
    const [ ing, setIng] = useState({});
    const [ resMessage, setResMessage] = useState("");


    async function handleScan(data) {
        if (data) {            
            const dataSplit = data.split('/')
            const datasSet = dataSplit[dataSplit.length - 1]; 

            setStatus(4);
            let token = localStorage.token;       
            const response = await api.get('v1/ingresso/ler/' + datasSet, {
                headers:{
                     Authorization: "Bearer " + token
                }
            })

            let res = response.data.data;

            setResMessage(res.message);
            setIng(res.ingresso);
      
            if (res.status) {
                setStatus(2);
            } else {
                setStatus(3);
            }
        }
    }

    useEffect( ()=> {
    },  []);

    const handleError = err => {
        console.error(err)
    }

    const lerQrcode = e => {

        e.preventDefault();
        setStatus(1);
        setIng({});
    }


    return (
        <div className="container-home">


            <div className="container-capa">
                <img src={Photocapa} alt="photo-capa" className="photo-capa" />
                <ButtonLogout title="SALIR"/>
                <User />
            </div>
            <Navbar />

           <div className="container-main-pai">
               <h1 className="title-informations" style={{marginBottom: 30}}>Validación</h1>
           </div> 

            {  
                status == 0 ?
                <div className="buttons-autenticar">
                    <button className="btn-autenticar" onClick={lerQrcode}>
                        Leer QR
                    </button>
                </div>
                :
                status == 1 ?
                <div className="qr-area-read">
                    <QrReader
                    className="qr-image-wrapper"
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                    />
                </div>
                :
                status == 2 ?
                <div className="message-autenticar success">
                    <img src="https://api-cinesol.busticket.cl/images/check.png" style={{width: "120px"}} alt="" />
                    <h1>{resMessage}</h1>
                    <button className="btn-autenticar" onClick={lerQrcode}>
                        Leer QR
                    </button>
                    <table className="tbl-ingresso">
                        <tr>
                            <th>Película</th>
                            <td>{ing.horario.filme.titulo}</td>
                        </tr>
                        <tr>
                            <th>Sala</th>
                            <td>{ing.horario.sala.nome}</td>
                        </tr>
                        <tr>
                            <th>Fecha</th>
                            <td>{ing.horario.data}</td>
                        </tr>
                        <tr>
                            <th>Hora</th>
                            <td>{ing.horario.horario}</td>
                        </tr>
                        <tr>
                            <th>Asiento</th>
                            <td>{ing.assento_nome}</td>
                        </tr>
                    </table>
                </div>
                :
                status == 3 ?
                <div className="message-autenticar error">
                    <img src="https://api-cinesol.busticket.cl/images/error.png" style={{width: "120px"}} alt="" />
                    <h1>{resMessage}</h1>
                    <button className="btn-autenticar" onClick={lerQrcode}>
                        Leer QR
                    </button>
                    {
                        ing.id ? 
                        <table className="tbl-ingresso">
                            <tr>
                                <th>Película</th>
                                <td>{ing.horario.filme.titulo}</td>
                            </tr>
                            <tr>
                                <th>Sala</th>
                                <td>{ing.horario.sala.nome}</td>
                            </tr>
                            <tr>
                                <th>Fecha</th>
                                <td>{ing.horario.data}</td>
                            </tr>
                            <tr>
                                <th>Hora</th>
                                <td>{ing.horario.horario}</td>
                            </tr>
                            <tr>
                                <th>Asiento</th>
                                <td>{ing.assento_nome}</td>
                            </tr>
                        </table>
                        :
                        <></>
                    }
                </div>
                :
                <div className="message-autenticar">
                    <h1>Aguarde...</h1>
                </div>
            }
        </div>
    );
};